<template>
	<div class="commodity-list">
		<el-table :data="goodsList.slice((currentPage-1)*pagesize, (currentPage-1)*pagesize+pagesize)">
			<el-table-column prop="buy_code" label="订货备件代码" width="200"></el-table-column>
			<el-table-column prop="sup_code" label="供应商代码" align="center" width="100"></el-table-column>
			<el-table-column prop="goods_name" label="零件名称">
				<template slot-scope="scope">
					<div class="goods-name">
						<i class="el-icon-picture"
							v-if="scope.row.goods_image!=='https://fawimage.oss-cn-zhangjiakou.aliyuncs.com/10001/images/goods_default.png'" @click="imgViewer(scope.row.goods_image)"></i>
						<router-link :to="{ path: '/details', query: { id: scope.row.goods_id } }"  target='_blank'>
							{{scope.row.goods_name}}
						</router-link>
					</div>
					<!-- <img class="goods-img" :src="scope.row.goods_image" /> -->
				</template>
			</el-table-column>
			<el-table-column prop="goods_price" label="单价（元）" align="center" width="100">
				<template slot-scope="scope">
					¥{{scope.row.grade_goods_price}}
				</template>
			</el-table-column>
			<el-table-column prop="total_num" label="数量" align="center" width="100"></el-table-column>
			<el-table-column prop="spec" label="小计（元）" align="center" width="150">
				<template slot-scope="scope">
					<span class="goods-price">¥{{scope.row.total_price}}</span>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页 -->
		<div class="paging">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
				:current-page.sync="currentPage" :page-size="pagesize" layout="prev, pager, next, jumper" :total="goodsList.length">
			</el-pagination>
		</div>
		<!-- 图片预览 -->
		<el-image-viewer v-if="showPictureView" :zIndex="Number(99999)" :on-close="closeImgViewer" :url-list="viewImgList" :initialIndex="viewPicIndex" />
	</div>
</template>

<script>
	import elImageViewer from 'element-ui/packages/image/src/image-viewer';
	export default {
		components: {
		  elImageViewer
		},
		props: {
			goodsList: Array
		},
		data() {
			return {
				currentPage: 1,
				pagesize: 20,
				total: 0,
				showPictureView: false,
				viewPicIndex: 0,
				viewImgList: [],
				cartProductList: [{
					id: 1,
					img: require('../../assets/img/product-img01.jpg'),
					title: '东风EQ153军车EQ1118GAEQ2102驾驶室仪表板总成3801N12-010',
					storehouse: '山东',
					unitprice: '10.00',
					subtotal: '10.00',
					number: 1,
					checked: false,
					stock: 10
				}, {
					id: 1,
					img: require('../../assets/img/product-img02.jpg'),
					title: '东风EQ153军车EQ1118GAEQ2102驾驶室仪表板总成3801N12-010',
					storehouse: '北京',
					unitprice: '20.00',
					subtotal: '20.00',
					number: 1,
					checked: false,
					stock: 3
				}]
			};
		},
		methods:{
			imgViewer(img){
				document.body.style.overflow = "hidden";
				this.showPictureView = true;
				this.viewImgList=[img]
			},
			closeImgViewer() {
				document.body.style.overflow = "auto";
				this.showPictureView = false;
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val
			},
		}
	}
</script>

<style lang="scss" scoped>
	.goods-name {
		i {
			color: $main-color;
			cursor: pointer;
		}
	}

	.goods-price {
		color: $main-red-color;
		font-size: 16px;
	}
	.paging{
		margin: 20px 0;
		text-align: center;
	}
</style>
