import request from '@/utils/request'

// api地址
const api = {
  all: 'region/all',
  tree: 'region/tree'
}

// 获取所有地区
export const all = (param) => {
  return request({
      url: api.all,
      method: 'get',
  	params: param,
    })
}

// 获取所有地区(树状)
export const tree = (param) => {
  return request({
      url: api.tree,
      method: 'get',
  	params: param,
    })
}
