import request from '@/utils/request'

// api地址
const api = {
    list: 'address/list',
    defaultId: 'address/defaultId',
    detail: 'address/detail',
    add: 'address/add',
    edit: 'address/edit',
    setDefault: 'address/setDefault',
    remove: 'address/remove',
    setTop: 'address/setTop',
    nlpAddress: 'address/nlpAddress',
}

// 收货地址列表
export const list = (param) => {
  return request({
    url: api.list,
    method: 'get',
  	params: param,
  })
}

// 默认收货地址ID
export const defaultId = (param) => {
  return request({
    url: api.defaultId,
    method: 'get',
  	params: param,
  })
}

// 收货地址详情
export const detail = (addressId) => {
  return request({
    url: api.detail,
    method: 'get',
  	params: { addressId },
  })
}

// 新增收货地址
export const add = (data) => {
  return request({
    url: api.add,
    method: 'post',
  	data: { form: data }
  })
}

// 编辑收货地址
export const edit = (addressId, data) => {
  return request({
    url: api.edit,
    method: 'post',
  	data: { addressId, form: data }
  })
}

// 设置默认收货地址
export const setDefault = (addressId) => {
	let data = { addressId }
  return request({
    url: api.setDefault,
    method: 'post',
	data
  })
}

// 删除收货地址
export const remove = (addressId) => {
	let data = { addressId }
  return request({
    url: api.remove,
    method: 'post',
	data
  })
}

export const setTop = (addressId) => {
    let data = { addressId }
    return request({
        url: api.setTop,
        method: 'post',
        data
    })
}

export const nlpAddress = (param) => {
    return request({
        url: api.nlpAddress,
        method: 'get',
        params: param,
    })
}
