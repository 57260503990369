import request from '@/utils/request'

// api地址
const api = {
  list: 'warehouse/list',
}

export function list(param) {
    return request({
        url: api.list,
        method: 'get',
        params: param,
    })
}
