<template>
	<div class="settlement">
		<!-- 头部 -->
		<mainHeader></mainHeader>
		<div class="simple-header clearfix">
			<div class="wrapper">
				<router-link to="/"><img src="../../assets/img/logo.png" class="logo" /></router-link>
				<span class="title">结算页</span>
			</div>
		</div>
		<div class="wrapper" v-if="order.goodsList.length">
			<!-- 收货地址 -->
			<shippingAddress v-if="curDelivery == DeliveryTypeEnum.EXPRESS.value"
                             @checkAddr="checkAddr"
                             :default_address_id="default_address_id"
            ></shippingAddress>

			<!-- 商品清单 -->
			<div class="settlement-title clearfix">
				<span class="">商品清单</span>
				<em @click="prev">返回修改购物车</em>
			</div>
			<commodity :goodsList="order.goodsList"></commodity>

			<div class="settlement-title clearfix">
				<span class="">偏好仓库</span>
			</div>
			<el-select class="message" clearable v-model="warehouse" placeholder="请选择偏好仓库">
			  <el-option
			    v-for="item in warehouseList"
			    :key="item.name"
			    :label="item.name"
			    :value="item.name">
			  </el-option>
			</el-select>
			<span class="tips"><i class="el-icon-warning"></i>选择后将在有货的前提下优先从该仓库发货</span>

			<!-- 给卖家留言 -->
			<div class="settlement-title clearfix">
				<span class="">给卖家留言</span>
			</div>
			<el-input type="textarea" class="message" placeholder="选填:给卖家留言" v-model="remark"></el-input>

			<!-- 优惠券 -->
			<!-- <div class="settlement-title clearfix">
				<span class="">使用优惠券</span>
			</div>
			<div class="settlement-coupon clearfix">
				<el-empty :image-size="50" description="暂无可用优惠券" v-if="order.couponList.length==0"></el-empty>
				<ul>
					<li v-for="(item, index) in order.couponList" :key="index"
					 v-if="item.is_apply"
					 :class="{'active':couponIndex === index}" @click="handleSelectCoupon(index)">
						<div class="top">
							<p class="money" v-if="item.coupon_type == CouponTypeEnum.FULL_DISCOUNT.value">
								￥{{ item.reduce_price }}<span>满{{ item.min_price }}</span>
							</p>
							<p class="money" v-if="item.coupon_type == CouponTypeEnum.DISCOUNT.value">
								{{ item.discount }}折<span>满{{ item.min_price }}</span>
							</p>
							<p class="time">{{ item.start_time }}~{{ item.end_time }}</p>
						</div>
						<div class="tips">{{ item.name }} {{ CouponTypeEnum[item.coupon_type].name }}</div>
					</li>
				</ul>
			</div> -->

			<!-- 支付方式 -->
			<div class="payment">
				<div class="settlement-title clearfix">
					<span class="">支付方式</span>
				</div>
				<div class="payment-con">
					<el-radio-group v-model="paymentRadio" @change="selectPaytype">
						<el-radio label="1" border>余额支付</el-radio>
                        <el-radio label="3" border>微信支付</el-radio>
					</el-radio-group>
				</div>
			</div>
			<!-- 结算 -->
			<div class="settlement-title clearfix">
				<span class="">结算信息</span>
			</div>
			<div class="settlement-bottom clearfix">
				<p><span>¥{{ order.orderTotalPrice }}</span><em>共<i>{{ order.orderTotalNum }}</i>件商品，总计：</em></p>
				<!-- <p v-if="order.address">
					<span v-if="order.isIntraRegion">+¥{{ order.expressPrice }}</span>
					<span v-else>不在配送范围</span>
					<em>运费：</em>
				</p>
				<p v-else><span>请先选择配送地址</span><em>运费：</em></p> -->
				<!-- <p v-if="order.couponList.length > 0">
					<span v-if="order.couponId > 0">-¥{{ order.couponMoney }}</span>
					<span v-else>有{{ order.couponList.length }}张优惠券</span>
					<em>优惠券：</em>
				</p>
				<p v-else><span>无优惠券可用</span><em>优惠券：</em></p> -->
				<p class="total"><span>¥{{ order.orderPayPrice }}</span><em>应付金额：</em></p>
				<p v-if="paymentRadio==1"><span>¥{{ assets.balance }}</span><em>当前账户余额：</em></p>
				<p v-if="paymentRadio==1"><span>¥{{ (assets.balance-order.orderPayPrice).toFixed(2) }}</span><em>本单结算后账户余额：</em></p>
				<p class="btn-wrapper">
					<em class="tips" v-if="order.hasError"><i class="el-icon-warning"></i>{{order.errorMsg}}</em>
					<el-button type="danger"  :disabled="disabled" @click="onSubmitOrder()" class="submit-btn">提交订单</el-button>
				</p>
			</div>
		</div>
		<!-- 尾部 -->
		<mainFooter></mainFooter>
	</div>
</template>

<script>
	import * as CheckoutApi from '@/api/checkout'
	import * as UserApi from '@/api/user'
	import { CouponTypeEnum } from '@/common/enum/coupon'
	import DeliveryTypeEnum from '@/common/enum/order/DeliveryType'
	import PayTypeEnum from '@/common/enum/order/PayType'
	import mainHeader from '../../components/header.vue'
	import mainFooter from '../../components/footer.vue'
	import shippingAddress from './address.vue'
	import commodity from './commodity.vue'
    import * as WarehouseApi from '@/api/warehouse'
	export default {
		components: {
			mainHeader,
			mainFooter,
			shippingAddress,
			commodity
		},
		data() {
			return{
				// 账户资产
				assets: { balance: '--', points: '--', coupon: '--' },
				// 枚举类
				DeliveryTypeEnum,
				PayTypeEnum,
				CouponTypeEnum,
				// 当前页面参数
				options: {},
				// 系统设置
				setting: {},
				// 当前选中的配送方式
				curDelivery: null,
				// 当前选中的支付方式
				curPayType: PayTypeEnum.BALANCE.value,
				// 选择的优惠券
				selectCouponId: 0,
				// 是否使用积分抵扣
				isUsePoints: false,
				// 买家留言
				remark: '',
				warehouse: '',
				// 禁用submit按钮
				disabled: false,
				// 订单信息 (从后端api中获取)
				order: {
				  // 商品列表
				  goodsList: [],
				  // 优惠券列表
				  couponList: [],
				  // 是否存在收货地址
				  existAddress: false,
				  // 默认收货地址
				  address: null,
				  // 是否存在收货地址
				  existAddress: false,
				  // 当前用户收货城市是否存在配送规则中
				  isIntraRegion: true,
				  // 是否存在错误
				  hasError: false,
				  // 错误信息
				  errorMsg: '',
				},

				message:'',
				couponIndex:'',
				paymentRadio:"1",
                default_address_id: 0,
                warehouseList: []
			}
		},
		created() {
			console.log(this.options)
			this.options = this.$route.query
			console.log(this.options)
			// 获取当前订单信息
			this.getOrderData()
			this.getAsset()
            this.getWarehouseList()
		},
		methods:{
			getAsset(){
				let app = this
				UserApi.assets()
					.then(result => {
						app.assets = result.data.assets
					})
			},
			checkAddr(){
				console.log('checkaddr')
				this.getOrderData()
			},
			couponItemClick(item, index){
				this.couponIndex = index
			},
			prev(){
				this.$router.go(-1)
			},
			// 获取订单数据
			getOrderData() {
			  const app = this
			  // 请求的参数
			  const params = {
			    delivery: app.curDelivery || 0,
			    couponId: app.selectCouponId || 0,
			    isUsePoints: app.isUsePoints ? 1 : 0,
			  }
			  // 请求api
			  CheckoutApi.order(app.options.mode, app.getRequestParam())
			    .then(result => {
			      app.initData(result.data)
			    })
			    .catch(err => err)
			},

			// 初始化数据
			initData({ order }) {
			  const app = this
			  app.order = order
			  // 显示错误信息
			  if (order.hasError) {
				app.$message.error(order.errorMsg)
                  app.disabled = true
			  } else {
                  app.disabled = false
              }
			  // 当前选择的配送方式
			  app.curDelivery = order.delivery
			  // 当前选择支付方式 (如果是微信小程序默认使用微信支付)
			  if (app.$platform === 'MP-WEIXIN') {
			    app.curPayType = PayTypeEnum.WECHAT.value
			  }
			},

			// 获取api请求的参数
			getRequestParam() {
			  const app = this
			  const { options } = app
			  // 结算模式的固定参数
			  const modeParam = {}
			  // 结算模式: 立即购买
			  if (options.mode === 'buyNow') {
			    modeParam.goodsId = options.goodsId
			    modeParam.goodsNum = options.goodsNum
			    modeParam.goodsSkuId = options.goodsSkuId
			  }
			  // 结算模式: 购物车
			  if (options.mode === 'cart') {
			    modeParam.cartIds = options.cartIds
                  this.default_address_id = options.address_id
			  }
			  if (options.mode === 'import') {
				modeParam.importId = options.importId
			  }
			  // 订单结算参数(用户选择)
			  const orderParam = {
			    delivery: app.curDelivery || 0,
			    couponId: app.selectCouponId || 0,
			    isUsePoints: app.isUsePoints ? 1 : 0,
			  }
			  return { ...orderParam, ...modeParam }
			},
			// 选择优惠券
			handleSelectCoupon(index) {
			  const app = this
			  const { couponList } = app.order
			  // 当前选择的优惠券
			  const couponItem = couponList[index]
			  // 判断是否在适用范围
			  if (!couponItem.is_apply) {
				app.$message.error(couponItem.not_apply_info)
			    return
			  }
			  // 记录选中的优惠券id
			  app.selectCouponId = couponItem.user_coupon_id
			  // 重新获取订单信息
			  app.getOrderData()
			  this.couponIndex = index
			},
			// 选择支付方式
			handleSelectPayType(value) {
			  this.curPayType = value
			},
			// 订单提交
			onSubmitOrder() {
			  const app = this
			  if (app.disabled) {
			    return false
			  }
			  // 表单验证
			  if (!app.onVerifyFrom()) {
			    return false
			  }
			  // 按钮禁用
			  app.disabled = true
			  // 请求api
			  CheckoutApi.submit(app.options.mode, app.getFormData())
			    .then(result => app.onSubmitCallback(result))
			    .catch(err => {
			      if (err.result) {
			        const errData = err.result.data
			        if (errData.is_created) {
					  app.navToMyOrder()
			          return false
			        }
			      }
			      app.disabled = false
			    })
			},

			// 订单提交成功后回调
			onSubmitCallback(result) {
				console.log(result)
			  const app = this
			  // 发起微信支付
			  if (result.data.payType == PayTypeEnum.WECHAT.value) {
                  this.$router.push('/settlement/wxpay?id=' + result.data.orderId)
			  }
			  // 余额支付
			  if (result.data.payType == PayTypeEnum.BALANCE.value) {
				app.$message.success('支付成功')
			    app.disabled = false
			    app.navToMyOrder()
			  }
			  if (result.data.payType == PayTypeEnum.NUONUO.value) {
				  let payment = JSON.parse(result.data.payment)
				  if(payment.code != "JH200"){
				  	this.$message.error(payment.describe)
				  }else{
					this.$router.push('/settlement/nuonuo?id='+result.data.orderId+'&payment='+encodeURIComponent(payment.result.payUtl))
				  }
			  }
			},

			// 跳转到我的订单(等待1秒)
			navToMyOrder() {
			  setTimeout(() => {
			    this.$router.push("/myhome/myorder")
			  }, 1000)
			},

			// 表单提交的数据
			getFormData() {
			  const app = this
			  const { options } = app
			  // 表单数据
			  const form = {
			    delivery: app.curDelivery,
			    payType: app.curPayType,
			    couponId: app.selectCouponId || 0,
			    isUsePoints: app.isUsePoints ? 1 : 0,
			    remark: app.remark || '',
				warehouse: app.warehouse,
          orderPayPrice: app.order.orderPayPrice
			  }
			  // 创建订单-立即购买
			  if (options.mode === 'buyNow') {
			    form.goodsId = options.goodsId
			    form.goodsNum = options.goodsNum
			    form.goodsSkuId = options.goodsSkuId
			  }
			  // 创建订单-购物车结算
			  if (options.mode === 'cart') {
			    form.cartIds = options.cartIds || null
			  }
			  // 创建订单-导入订单结算
			  if (options.mode === 'import') {
			    form.importId = options.importId || null
			  }
			  return form
			},

			// 表单验证
			onVerifyFrom() {
			  const app = this
			  if (app.hasError) {
				app.$message.error(app.errorMsg)
			    return false
			  }
			  return true
			},
			selectPaytype(e){
				console.log(e)
				if(e == 1){
					this.curPayType = PayTypeEnum.BALANCE.value
				}
				if(e==2){
					this.curPayType = PayTypeEnum.NUONUO.value
				}
                if(e==3){
                    this.curPayType = PayTypeEnum.WECHAT.value
                }
			},
            getWarehouseList() {
                const app = this
                WarehouseApi.list()
                    .then(result => {
                        app.warehouseList = result.data.list.concat({
                            name: '总库',
                            code: ''
                        })
                    })
            }
		}
	}
</script>

<style lang="scss">
	@import "../../assets/css/settlement.scss";
	.tips{
		margin: 0 0 0 10px;
		color:$main-red-color;
		i{
			margin: 0 5px 0 0;
		}
	}
</style>
