<template>
	<div class="success" :class="{'fail':order.pay_status != 20}">
		<!-- 头部 -->
		<mainHeader :cartAdd="cartAdd"></mainHeader>
		<!-- 导航 -->
		<!-- <navigationSecond></navigationSecond> -->

		<div class="success-header">
			<i class="el-icon-success" v-if="order.pay_status == 20"></i>
			<i class="el-icon-error" v-else></i>
			<div class="txt" v-if="order.pay_status == 20">
				<p>支付成功！</p>
				<p>感谢您的购买</p>
			</div>
			<div class="txt" v-else>
				<p>支付未成功！</p>
				<p>如果您确认已支付，请稍候刷新查看支付状态！</p>
			</div>
		</div>

		<div class="content">
			<div class="info">
				<div class="price">￥{{order.pay_price}}</div>
				<p>订单编号: {{order.order_no}}</p>
				<p>下单时间: {{order.create_time}}</p>
				<p>支付方式:{{PayTypeEnum[order.pay_type].name}}</p>
			</div>
		</div>

		<div class="btn">
			<el-button type="success" @click="goDetail" round>订单详情</el-button>
		</div>
		<!-- 尾部 -->
		<mainFooter></mainFooter>
	</div>
</template>

<script>
	import * as OrderApi from '@/api/order'
	import {
		PayTypeEnum,
	} from '@/common/enum/order'
	import mainHeader from '../../components/header.vue'
	// import navigationSecond from '../../components/navigation-second.vue'
	import mainFooter from '../../components/footer.vue'
	export default {
		components: {
			mainHeader,
			// navigationSecond,
			mainFooter
		},
		data() {
			return {
				PayTypeEnum,
				// 当前订单ID
				orderId: null,
				// 当前订单详情
				order: {},
			}
		},
		created() {
			if (this.$route.query.id) {
				this.orderId = this.$route.query.id
			}
			this.getOrderDetail()
		},
		methods: {
			// 获取当前订单信息
			getOrderDetail() {
				const app = this
				OrderApi.detail(app.orderId)
					.then(result => {
						app.order = result.data.order
						app.order.total_num = 0
						app.order.goods.forEach(goods => {
							app.order.total_num += goods.total_num
						})
						app.setting = result.data.setting
					})
			},
			goDetail() {
				this.$router.push('/myhome/orderdetails?id=' + this.orderId)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.success {

		// background: #FFF;
		.success-header {
			background: #1db476;
			background-image: linear-gradient(to bottom, #1db476, #64e99f);
			color: #FFF;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 60px 0 80px 0;

			i {
				font-size: 80px;
			}

			.txt {
				font-size: 20px;
				margin: 0 0 0 20px;

				p {
					&:first-child {
						font-size: 30px;
						margin: 0 0 5px 0;
					}
				}
			}
		}

		.content {
			width: 500px;
			height: 15px;
			border-radius: 15px;
			background: #20b97a;
			margin: -40px auto 350px auto;
			position: relative;

			.info {
				background: #FFF;
				background: linear-gradient(to bottom, #b8e9d4 0.1%, #FFF, #FFF, #FFF, #FFF, #FFF, #FFF, #FFF);
				box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
				position: absolute;
				left: 20px;
				right: 20px;
				top: 7px;
				padding: 10px 10px 40px 10px;

				.price {
					font-size: 36px;
					text-align: center;
					padding: 30px;
					border-bottom: 1px dashed #eeeeee;
					margin-bottom: 20px;
				}

				p {
					padding: 10px 20px;
					font-size: 14px;
					color: #666;
				}
			}
		}

		.btn {
			text-align: center;

			.el-button {
				width: 300px;
				font-size: 18px;
				margin: 0 auto 50px auto;
				border-radius: 30px;
				line-height: 1.3;
				background: #1db476;
			}
		}
	}

	.fail {
		.success-header {
			background: #f4516c;
			background-image: linear-gradient(to bottom, #f4516c, #f67b8f);
		}

		.content {
			background: #ec3855;

			.info {
				background: linear-gradient(to bottom, #fdbdc7 0.1%, #FFF, #FFF, #FFF, #FFF, #FFF, #FFF, #FFF);
			}
		}

		.btn {
			.el-button {
				background: #f4516c;
				border: 1px solid #f67b8f;
			}
		}
	}
</style>
