<template>
	<div class="address-add">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="智能识别" prop="">
        <el-input v-model="ruleForm.text" placeholder="请输入完整收货地址信息进行识别"></el-input>
        <el-button type="primary" @click="nlp_address()">识别</el-button>
      </el-form-item>
			<el-form-item label="姓名" prop="name">
				<el-input v-model="ruleForm.name" placeholder="请输入收货人姓名"></el-input>
			</el-form-item>

			<el-form-item label="手机" prop="phone">
				<el-input v-model="ruleForm.phone" placeholder="请输入收货人手机号"></el-input>
			</el-form-item>

			<el-form-item label="所在地址" prop="region">
				<el-cascader class="widthSmall" size="large" :options="region" :props="{ checkStrictly: true }" v-model="areaData" placeholder="请输入所在地址"
					@change="addressChange">
				</el-cascader>
			</el-form-item>

			<el-form-item label="详细地址" prop="detail">
				<el-input v-model="ruleForm.detail" placeholder="请输入详细地址"></el-input>
			</el-form-item>

			<!-- <el-form-item label="设为默认" prop="default">
				<el-switch v-model="ruleForm.default"></el-switch>
			</el-form-item> -->

			<el-form-item>
				<el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
				<el-button @click="resetForm('ruleForm')">重置</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import * as AddressApi from '@/api/address'
  import {nlpAddress} from "../api/address";
	export default {
		props:{
			region: Array,
			address: Object
		},
		data() {
			return {
				address_id: 0,
				areaData: [],
				ruleForm: {
					name: '',
					phone: '',
					region: [], // 选中的地区
					detail:''
				},
				rules: {
					name: [{
							required: true,
							message: '请输入姓名',
							trigger: 'blur'
						}
					],
					phone: [{
							required: true,
							message: "请输入手机号码",
							trigger: "blur"
						},
						// {
						// 	min: 11,
						// 	max: 11,
						// 	message: "请输入11位手机号码",
						// 	trigger: "blur"
						// },
						// {
						// 	pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[0-9])\d{8}$/,
						// 	message: "请输入正确的手机号码"
						// }
					],
					region: [{
						required: true,
						message: '请选择所在地址',
						trigger: 'blur'
					}],
					detail: [{
						required: true,
						message: '请输入详细地址',
						trigger: 'blur'
					}]
				}
			};
		},
		watch:{
			address(){
				this.getData()
			}
		},
		created() {
			this.getData()
		},
		methods: {
      nlp_address() {
        let text = this.ruleForm.text
        AddressApi.nlpAddress({ text: text }).then(res => {
          let data = res.data.result
          this.ruleForm.name = data.person
          this.ruleForm.phone = data.phonenum
          this.ruleForm.detail = data.town + data.detail
          this.areaData = []
          this.ruleForm.region = []
					if (data.province_id !== 0) {
						this.areaData.push(data.province_id)
						this.ruleForm.region.push({ value: data.province_id, label: 'none' })
						if (data.city_id !== 0) {
						  this.areaData.push(data.city_id)
							this.ruleForm.region.push({ value: data.city_id, label: 'none' })
						  if (data.region_id !== 0) {
						    this.areaData.push(data.region_id)
								this.ruleForm.region.push({ value: data.region_id, label: 'none' })
						  }
						}
					}
        })
      },
			getData(){
				if(this.address.address_id){
					this.address_id = this.address.address_id
					this.ruleForm.name = this.address.name
					this.ruleForm.phone = this.address.phone
					this.ruleForm.detail = this.address.detail
					this.areaData = []
					this.areaData.push(this.address.province_id)
					if(this.address.city_id!==0){
						this.areaData.push(this.address.city_id)
						if(this.address.region_id!==0){
							this.areaData.push(this.address.region_id)
						}
					}
					this.ruleForm.region = []
					this.ruleForm.region.push({value: this.address.province_id, label: 'none'})
					this.ruleForm.region.push({value: this.address.city_id, label: 'none'})
					this.ruleForm.region.push({value: this.address.region_id, label: 'none'})
				}else{
					this.address_id = 0
					this.ruleForm.name = ''
					this.ruleForm.phone = ''
					this.ruleForm.detail = ''
					this.areaData = []
					this.ruleForm.region = []
				}
				this.$forceUpdate()
			},
			addressChange(arr) {
				this.ruleForm.region = []
				this.ruleForm.region.push({value: arr[0], label: 'none'})
				this.ruleForm.region.push({value: arr[1], label: 'none'})
				this.ruleForm.region.push({value: arr[2], label: 'none'})
			},
			submitForm(formName) {
				let app = this
				this.$refs[formName].validate((valid) => {
					if (valid) {
						app.ruleForm.region.forEach(item=>{
							if(!item.value){
								item.value=0
							}
						})
						console.log(app.ruleForm)
						if(app.address_id == 0){
							AddressApi.add(app.ruleForm)
							  .then(result => {
							    app.$message.success(result.message)
								app.$emit('updateAddr')
							  })
						}else{
							AddressApi.edit(app.address_id, app.ruleForm)
							  .then(result => {
							    app.$message.success(result.message)
							    app.$emit('updateAddr')
							  })
						}
						
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		}
	}
</script>

<style>
</style>
