<template>
	<div class="nuonuo">
		<!-- 头部 -->
		<mainHeader></mainHeader>
		<!-- 导航 -->
		<navigation>
			<template slot="breadcrumb">
				<breadcrumb title="支付" routerPath="/settlement/wxpay"></breadcrumb>
			</template>
		</navigation>
		<!-- <mainHeader :cartAdd="cartAdd"></mainHeader> -->
		<div class="wrapper content">
			<div class="price">￥{{ order.pay_price }}</div>
			<div class="qrcode" ref="qrCodeUrl">
        <img :src="qrcode" />
      </div>
			<p class="tips">微信扫描上方二维码支付</p>
			<el-button class="btn-orange" type="warning" round @click="payClick()">支付完成</el-button>
			<p class="btn-tips">支付完成后，点击以上按钮</p>
		</div>

		<!-- 尾部 -->
		<mainFooter></mainFooter>
	</div>
</template>

<script>
	import * as OrderApi from '@/api/order'
	import QRCode from 'qrcodejs2'
	import mainHeader from '../../components/header.vue'
	import navigation from '../../components/navigation.vue'
	import breadcrumb from '../../components/breadcrumb.vue'
	import mainFooter from '../../components/footer.vue'
  import {getMpPathQrcode} from "../../api/order";
	export default {
		components:{
			mainHeader,
			navigation,
			breadcrumb,
			mainFooter
		},
		data() {
			return{
				payment: '',
				// 当前订单ID
				orderId: null,
				// 当前订单详情
				order: {},
				ordertype: 'order',
        qrcode: ''
			}
		},
		created() {
			if(this.$route.query.id){
				this.orderId = this.$route.query.id
				this.getOrderDetail()
			}
			if(this.$route.query.ordertype){
				this.ordertype = this.$route.query.ordertype
			}
			if(this.$route.query.price){
				this.order.pay_price = this.$route.query.price
			}
			this.creatQrCode()
		},
		methods:{
			payClick(){
				if(this.ordertype == 'order'){
					this.$router.push('/settlement/paySuccess?id='+this.orderId);
				}else{
					this.$router.push('/myhome/wallet');
				}

			},
			creatQrCode() {
				let app = this
        OrderApi.getMpPathQrcode(app.orderId).then(res => {
          this.qrcode = 'data:image/jpg;base64,' + res.data.qrcode
        })
			},
			// 获取当前订单信息
			getOrderDetail() {
			  const app = this
			  OrderApi.detail(app.orderId)
			    .then(result => {
			      app.order = result.data.order
				  app.order.total_num = 0
				  app.order.goods.forEach(goods => {
				    app.order.total_num += goods.total_num
				  })
			      app.setting = result.data.setting
			    })
			},
		}
	}
</script>

<style lang="scss" scoped>
	.nuonuo{
		background: #FFF;
		.content{
			text-align: center;
			padding: 50px 0;
			.price{
				font-size: 36px;
				color: #F90;
			}
			.qrcode{
			    display: inline-block;
					margin: 30px 0 0 0;
			    img {
			        width: 260px;
			        height: 260px;
			        background-color: #fff;
			        padding: 6px;
			        box-sizing: border-box;
			    }
			}
			.tips{
				background: #f2f2f2;
				width: 260px;
				line-height: 35px;
				margin: 0 auto;
			}
			.btn-orange{
				margin:50px auto 10px;
				width: 300px;
				font-size: 18px;
			}
		}
	}

</style>
