<template>
	<div class="address clearfix">
		<div class="settlement-title clearfix">
			<span class="">收货地址</span>
			<em @click="addClick()">添加新地址</em>
		</div>
		<div class="address-con clearfix">
			<el-empty :image-size="50" description="暂无收货地址,请右上角添加" v-if="list.length==0"></el-empty>
			<div class="address-con-title" v-if="list.length!==0">· 选中地址</div>
			<ul>
				<li class="active" v-for="(item,index) in list" :key="item.id" v-if="item.address_id == defaultId">
					<div class="top">
						<span>{{item.name}}</span>
						<em>选中</em>
					</div>
					<div class="address-phone"> 手机：{{item.phone}}</div>
					<div class="address-add line2" :title="item.region.province+item.region.city+item.region.region+item.detail">地址：<span v-for="(region, idx) in item.region" :key="idx">{{ region }}</span>{{ item.detail }}</div>
					<div class="btn">
						<div class="edit button-primary-mini" @click.stop="editClick(item)">编辑</div>
						<div class="edit button-warning-mini" @click.stop="handleRemove(item.address_id)">删除</div>
						<div class="edit button-primary-mini" v-if="item.is_top == 0" @click.stop="handleSetTop(item.address_id)">设为置顶</div>
					</div>
				</li>
			</ul>
			<div class="address-con-title" v-if="list.length!==0">· 常用地址</div>
			<ul>
				<li :class="{'active':activeIndex==index}" v-for="(item,index) in list" :key="item.id"
					@click="addressClick(index, item.address_id)" v-if="index<4">
					<div class="top">
						<span>{{item.name}}</span>
						<em v-if="item.is_top == 1">常用</em>
					</div>
					<div class="address-phone"> 手机：{{item.phone}}</div>
					<div class="address-add line2" :title="item.region.province+item.region.city+item.region.region+item.detail">地址：<span v-for="(region, idx) in item.region" :key="idx">{{ region }}</span>{{ item.detail }}</div>
					<div class="btn">
						<div class="edit button-primary-mini" @click.stop="editClick(item)">编辑</div>
						<div class="edit button-warning-mini" @click.stop="handleRemove(item.address_id)">删除</div>
					</div>
				</li>
			</ul>
			<div class="address-con-title" v-if="list.length>4">· 其他地址</div>
			<div class="address-select" v-if="list.length>4">
				<!-- <div class="title">其他收货地址：</div> -->
				<el-select style="width: 100%;" v-model="addressSelectValue" placeholder="请选择其他收货地址" clearable filterable @change="addressSelectChange">
					<el-option v-if="index>3"
						v-for="(item,index) in list"
						:key="item.address_id"
						:label="item.name+item.phone+item.region.province+item.region.city+item.region.region+item.detail"
						:value="item.address_id">
						<span>{{ item.name }}</span>
						<span style="margin: 0 10px;color: #8492a6;">{{ item.phone }}</span>
						<span><span v-for="(region, idx) in item.region" :key="idx">{{ region }}</span>{{ item.detail }}</span>
					</el-option>
				</el-select>
				<!-- <div class="tips">* 如果选中下拉框地址，以下拉框为主,否则以上面为主</div> -->
			</div>
		</div>
		<!-- 添加新地址 -->
		<el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
			<addressAdd :address="address" :region="region" @updateAddr="updateAddr"></addressAdd>
		</el-dialog>
	</div>
</template>

<script>
	import * as RegionApi from '@/api/region'
	import * as AddressApi from '@/api/address'
	import addressAdd from "../../components/address.vue"
	export default {
		components:{addressAdd},
        props: {
            default_address_id: {
                type: Number,
                required: false,
                default: 0
            }
        },
        watch: {
            default_address_id: {
                handler: function (val, oldVal) {
                    if(val > 0) {
                        this.handleSetDefault(val)
                    }
                },
                immediate: true
            }
        },
		data() {
			return {
				addressSelectValue:'',
				activeIndex: null,
				defaultId: '',
				address: '',
				title: '',
				dialogVisible: false,
				list: [],
				region: []
			}
		},
		mounted() {
			this.getRegion()
			this.getPageData()
			// this.dialogVisible = true
		},
		methods: {
			// 设为置顶
			handleSetTop(addressId) {
			    const app = this
			    app.$confirm('确定要设为置顶收货地址吗?', '提示', {
			        confirmButtonText: '确定',
			        cancelButtonText: '取消',
			        type: 'warning'
			    }).then(() => {
			        AddressApi.setTop(addressId)
			            .then(result => {
			                app.$message.success('设置成功')
			                app.getPageData()
			            })
			    }).catch(() => {

			    });
			},
			addressSelectChange(val){
				console.log(val)
				if(val==''){
					this.activeIndex = 0
					val=this.list[0].address_id
					this.handleSetDefault(val)
				}else{
					this.activeIndex = -1
					this.handleSetDefault(val)
				}
			},
			addressClick(index, address_id) {
				this.activeIndex = index
				this.handleSetDefault(address_id)
				this.addressSelectValue=''
			},
			editClick(item){
				this.title = '编辑收货地址'
				this.address = item
				this.dialogVisible = true
			},
			updateAddr(){
				this.getPageData()
				this.dialogVisible = false
                app.$emit('checkAddr')
			},
			//获取地区数据
			getRegion(){
				let app = this
				if(localStorage.getItem('region')){
					this.region = JSON.parse(localStorage.getItem('region'))
				}else{
					RegionApi.tree().then(result => {
						let list = result.data.list
						let region = []
						for(let sheng in list){
							let rSheng = {}
							rSheng.value = list[sheng].id
							rSheng.label = list[sheng].name
							rSheng.children = []
							let city = list[sheng].city
							for(let shi in city){
								let rShi = {}
								rShi.value = city[shi].id
								rShi.label = city[shi].name
								rShi.children = []
								let qu = city[shi].region
								for(let qukey in qu){
									let rQu = {}
									rQu.value = qu[qukey].id
									rQu.label = qu[qukey].name
									rShi.children.push(rQu)
								}
								rSheng.children.push(rShi)
							}
							region.push(rSheng)
						}
						localStorage.setItem('region', JSON.stringify(region))
						app.region = region
					})
				}
			},
			// 获取页面数据
			getPageData() {
			  const app = this
			  Promise.all([app.getDefaultId(), app.getAddressList()])
			    .then(() => {
			      // 列表排序把默认收货地址放到最前
				  app.onReorder()
				  app.dialogVisible = false
			    })
			},

			// 获取收货地址列表
			getAddressList() {
			  const app = this
			  return new Promise((resolve, reject) => {
			    AddressApi.list()
			      .then(result => {
			        app.list = result.data.list
			        resolve(result)
			      })
			      .catch(err => reject(err))
			  })
			},

			// 获取默认的收货地址
			getDefaultId() {
			  return new Promise((resolve, reject) => {
			    const app = this
			    AddressApi.defaultId()
			      .then(result => {
			        app.defaultId = result.data.defaultId
			        resolve(result)
			      })
			      .catch(err => reject(err))
			  })
			},

			// 列表排序把默认收货地址放到最前
			onReorder() {
			  // const app = this
			  // app.list.sort(item => {
			  //   return item.address_id == app.defaultId ? -1 : 1
			  // })
			},
			addClick() {
				this.title = '新增收货地址'
				this.address = {}
				this.dialogVisible = true
			},
			deleteClick(index){
				this.$confirm('确定要删除该行吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.addresList.splice(index, 1)
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
				}).catch(() => {});
			},
			/**
			 * 删除收货地址
			 * @param {int} addressId 收货地址ID
			 */
			handleRemove(addressId) {
			  const app = this
			  this.$confirm('您确定要删除当前收货地址吗?', '提示', {
			  	confirmButtonText: '确定',
			  	cancelButtonText: '取消',
			  	type: 'warning'
			  }).then(() => {
			  	app.onRemove(addressId)
			  }).catch(() => {});
			},

			/**
			 * 确认删除收货地址
			 * @param {int} addressId 收货地址ID
			 */
			onRemove(addressId) {
			  const app = this
			  AddressApi.remove(addressId)
			    .then(result => {
			      app.getPageData()
                    app.$emit('checkAddr')
			    })
			},
			/**
			 * 设置为默认地址
			 * @param {Object} addressId
			 */
			handleSetDefault(addressId) {
			  const app = this
			  AddressApi.setDefault(addressId)
			    .then(result => {
			      app.defaultId = addressId
                    app.$emit('checkAddr')
			    })
			}
		}
	}
</script>

<style lang="scss" scoped>
	.address-con-title{
		background: rgba(0, 0, 0, 0.05);
		padding: 10px 20px;
		margin: 0 0 10px 0;
	}
</style>
